import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import NewsletterService from 'services/NewsletterService'

export const initialState = {
	loading: false,
	subscribersList: [],
	subscriberFilters: [],
	subscriberPagination: {
		from: 1,
		to: 1,
		perPage: 1,
		total: 0,
		currentPage: 1,
		prevPage: null,
		nextPage: null,
		lastPage: 0,
	},
}

export const getSubscriberList = createAsyncThunk(
	'newsletter/subscribers',
	async (data, { rejectWithValue }) => {
		try {
			const response = await NewsletterService.getSubscribers(data)
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const deleteSubscriber = createAsyncThunk(
	'newsletter/subscribers/delete',
	async (subscriberId, { rejectWithValue }) => {
		try {
			const response = await NewsletterService.deleteSubscriber(subscriberId)
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const exportSubscriberList = createAsyncThunk(
	'newsletter/subscribers/export',
	async (data, { rejectWithValue }) => {
		try {
			let response = await NewsletterService.exportSubscribers(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const newsletterSlice = createSlice({
	name: 'newsletter',
	initialState,
	reducers: {
		showLoading: (state) => {
			state.loading = true
		},
		disableLoading: (state) => {
			state.loading = false
		},
	},
	extraReducers: (builder) => {
		builder
			// ^ List
			.addCase(getSubscriberList.pending, (state) => {
				state.subscribersList = []
				state.loading = true
			})
			.addCase(getSubscriberList.fulfilled, (state, action) => {
				state.loading = false
				state.subscribersList = action.payload.data
				state.subscriberFilters = action.payload.filters
				state.subscriberPagination = action.payload.pagination
			})
			.addCase(getSubscriberList.rejected, (state) => {
				state.loading = false
			})

			// ^ Delete article
			.addCase(deleteSubscriber.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteSubscriber.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message, 5)
			})
			.addCase(deleteSubscriber.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload, 5)
			})

			// ^ Export
			.addCase(exportSubscriberList.pending, (state) => {
				state.loading = true
			})
			.addCase(exportSubscriberList.fulfilled, (state, action) => {
				state.loading = false
			})
			.addCase(exportSubscriberList.rejected, (state) => {
				state.loading = false
			})
	},
})

export const { showLoading, disableLoading } = newsletterSlice.actions

export default newsletterSlice.reducer
