import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import React from 'react'

export const publicRoutes = [
	{
		key: 'login',
		path: `${AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() => import('views/auth-views/authentication/login')),
	},
	{
		key: 'register',
		path: `${AUTH_PREFIX_PATH}/register`,
		component: React.lazy(() => import('views/auth-views/authentication/register')),
	},
	{
		key: 'forgot-password',
		path: `${AUTH_PREFIX_PATH}/forgot-password`,
		component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
	},
	{
		key: 'new-password',
		path: `${AUTH_PREFIX_PATH}/new-password`,
		component: React.lazy(() => import('views/auth-views/authentication/new-password')),
	},
]

export const protectedRoutes = [
	{
		key: 'error',
		path: `${APP_PREFIX_PATH}/error`,
		component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
	},

	// ^ Articles
	{
		key: 'articles-list',
		path: `${APP_PREFIX_PATH}/articles`,
		component: React.lazy(() => import('views/app-views/articles')),
	},
	{
		key: 'articles-add',
		path: `${APP_PREFIX_PATH}/articles/add`,
		component: React.lazy(() => import('views/app-views/articles/add-article')),
	},
	{
		key: 'articles-edit',
		path: `${APP_PREFIX_PATH}/articles/edit/:id`,
		component: React.lazy(() => import('views/app-views/articles/edit-article')),
	},
	{
		key: 'articles-gallery',
		path: `${APP_PREFIX_PATH}/articles/gallery`,
		component: React.lazy(() => import('views/app-views/articleGallery')),
	},
	{
		key: 'articles-gallery-preview',
		path: `${APP_PREFIX_PATH}/articles/gallery/preview`,
		component: React.lazy(() => import('views/app-views/articleGallery/preview')),
	},

	// ^ Newsletter
	{
		key: 'newsletter-subscribers',
		path: `${APP_PREFIX_PATH}/newsletter/subscribers`,
		component: React.lazy(() => import('views/app-views/newsletter/subscribers')),
	},

	// ^ Settings
	{
		key: 'settings-homepage',
		path: `${APP_PREFIX_PATH}/settings/homepage`,
		component: React.lazy(() => import('views/app-views/settings/homepage')),
	},
	{
		key: 'settings-general',
		path: `${APP_PREFIX_PATH}/settings/general`,
		component: React.lazy(() => import('views/app-views/settings/general')),
	},
	{
		key: 'settings-side-panel',
		path: `${APP_PREFIX_PATH}/settings/side-panel`,
		component: React.lazy(() => import('views/app-views/settings/side-panel')),
	},
	{
		key: 'settings-milestones',
		path: `${APP_PREFIX_PATH}/settings/milestones`,
		component: React.lazy(() => import('views/app-views/settings/milestones')),
	},

	// ^ File manager
	{
		key: 'file-manager',
		path: `${APP_PREFIX_PATH}/file-manager`,
		component: React.lazy(() => import('views/app-views/file-manager')),
	},
]
