import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import MilestoneService from 'services/MilestoneService'
import SettingsService from 'services/SettingsService'

export const initialState = {
	loading: true,
	loadingArticleGallery: false,
	settingsLoaded: false,
	generalSettingsLoaded: false,
	articleGalleryLoaded: false,
	homeSettingsData: {
		sectionHero: {
			template: null,
			title: null,
			subtitle: null,
		},
		sectionAbout: {
			template: null,
			bio: null,
			bioCd: null,
		},
		customSections: [],
	},
	generalSettingsData: {
		contactMail: null,
		socials: {},
	},
	articleGalleryTemplate: null,
}

// * Homepage settings
export const getHomepageSettings = createAsyncThunk(
	'getSettings/homepage',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SettingsService.getHomepageSettings()
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)
export const setHomepageSettings = createAsyncThunk(
	'setSettings/homepage',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SettingsService.setHomepageSettings(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

// * General settings
export const getGeneralSettings = createAsyncThunk(
	'getSettings/general',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SettingsService.getSettings({ name: 'general' })
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)
export const setGeneralSettings = createAsyncThunk(
	'setSettings/general',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SettingsService.setSettings({
				name: 'general',
				content: data,
			})
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

// * Article gallery template.
export const getArticleTemplateSettings = createAsyncThunk(
	'getSettings/article-gallery',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SettingsService.getSettings({ name: 'article-gallery-template' })
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)
export const setArticleTemplateSettings = createAsyncThunk(
	'setSettings/article-gallery',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SettingsService.setSettings({
				name: 'article-gallery-template',
				content: data,
			})
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	extraReducers: (builder) => {
		builder
			// ^ Homepage | Get
			.addCase(getHomepageSettings.pending, (state) => {
				state.loading = true
				state.settingsLoaded = false
			})
			.addCase(getHomepageSettings.fulfilled, (state, action) => {
				state.homeSettingsData = action.payload
				state.loading = false
				state.settingsLoaded = true
			})
			.addCase(getHomepageSettings.rejected, (state) => {
				state.loading = false
			})

			// ^ Homepage | Set
			.addCase(setHomepageSettings.pending, (state) => {
				state.loading = true
			})
			.addCase(setHomepageSettings.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message)
			})
			.addCase(setHomepageSettings.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload)
			})

			// ^ General | Get
			.addCase(getGeneralSettings.pending, (state) => {
				state.loading = true
				state.generalSettingsLoaded = false
			})
			.addCase(getGeneralSettings.fulfilled, (state, action) => {
				state.generalSettingsData = action.payload
				state.loading = false
				state.generalSettingsLoaded = true
			})
			.addCase(getGeneralSettings.rejected, (state) => {
				state.loading = false
			})

			// ^ General | Set
			.addCase(setGeneralSettings.pending, (state) => {
				state.loading = true
			})
			.addCase(setGeneralSettings.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message)
			})
			.addCase(setGeneralSettings.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload)
			})

			// ^ Article gallery template | Get
			.addCase(getArticleTemplateSettings.pending, (state) => {
				state.loadingArticleGallery = true
				state.articleGalleryLoaded = false
			})
			.addCase(getArticleTemplateSettings.fulfilled, (state, action) => {
				state.articleGalleryTemplate = action.payload
				state.articleGalleryLoaded = true
				state.loadingArticleGallery = false
			})
			.addCase(getArticleTemplateSettings.rejected, (state) => {
				state.loadingArticleGallery = false
			})

			// ^ Article gallery template | Set
			.addCase(setArticleTemplateSettings.pending, (state) => {
				state.loadingArticleGallery = true
			})
			.addCase(setArticleTemplateSettings.fulfilled, (state, action) => {
				state.loadingArticleGallery = false
				message.success(action.payload.message)
			})
			.addCase(setArticleTemplateSettings.rejected, (state, action) => {
				state.loadingArticleGallery = false
				message.error(action.payload)
			})
	},
})

export default settingsSlice.reducer
