import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { setAutoFreeze } from 'immer'

// Fixes "Cannot assign to read only property" error message
// when modifying objects from Redux state directly.
// https://github.com/reduxjs/redux-toolkit/issues/424
setAutoFreeze(false)

const middlewares = []

const store = configureStore({
	reducer: rootReducer(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {}

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false
	}
	store.asyncReducers[key] = reducer
	store.replaceReducer(rootReducer(store.asyncReducers))
	return store
}

export default store
