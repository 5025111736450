import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import ArticlesService from 'services/ArticlesService'

export const initialState = {
	loading: false,
	loadingSidePanel: false,
	sidePanelArticles: [],
	articlesList: [],
	articleFilters: [],
	articleCategoriesList: [],
	articleSectionsList: [],
	articlePagination: {
		from: 1,
		to: 1,
		perPage: 1,
		total: 0,
		currentPage: 1,
		prevPage: null,
		nextPage: null,
		lastPage: 0,
	},
	articleGalleryLoaded: false,
	articleGalleryData: {
		selectedTemplateId: null,
		selectedTemplateRows: null,
		templateList: [],
	},
	articleGalleryContent: [],
	articleGalleryOrders: [],
	articleData: null,
}

export const getArticleList = createAsyncThunk('articles', async (data, { rejectWithValue }) => {
	try {
		const response = await ArticlesService.getArticles(data)
		return response.result
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getArticleGallery = createAsyncThunk(
	'articles/gallery',
	async (data, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.getArticles(data)
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const saveArticleGallery = createAsyncThunk(
	'articles/gallery/save',
	async (data, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.saveArticleGallery(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const addOrUpdateArticle = createAsyncThunk(
	'articles/add',
	async (data, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.addOrUpdateArticle(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const getArticle = createAsyncThunk(
	'articles/fetchById',
	async (articleId, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.getArticle(articleId)
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const addCategory = createAsyncThunk(
	'articles/categories/add',
	async (name, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.addCategory({ name })
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const deleteCategory = createAsyncThunk(
	'articles/categories/delete',
	async (id, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.deleteCategory({ id })
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const getArticleCategories = createAsyncThunk(
	'articles/categories',
	async (data, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.getArticleCategories()
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const deleteArticle = createAsyncThunk(
	'articles/delete',
	async (articleId, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.deleteArticle(articleId)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const saveSidePanelArticles = createAsyncThunk(
	'articles/side-panel/save',
	async (data, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.saveSidePanelArticles({ articles: data })
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const getSidePanelArticles = createAsyncThunk(
	'articles/side-panel/get',
	async (data, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.getSidePanelArticles()
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const getArticleSections = createAsyncThunk(
	'articles/sections',
	async (data, { rejectWithValue }) => {
		try {
			const response = await ArticlesService.getArticleSections()
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const articlesSlice = createSlice({
	name: 'articles',
	initialState,
	reducers: {
		updateArticleList: (state, action) => {
			const { articles, orders } = action.payload

			state.articleGalleryContent = articles
			state.articleGalleryOrders = orders
		},
		showLoading: (state) => {
			state.loading = true
		},
		disableLoading: (state) => {
			state.loading = false
		},
	},
	extraReducers: (builder) => {
		builder
			// ^ Add or update articles.
			.addCase(addOrUpdateArticle.pending, (state) => {
				state.loading = true
			})
			.addCase(addOrUpdateArticle.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message)
			})
			.addCase(addOrUpdateArticle.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload)
			})

			// ^ List
			.addCase(getArticleList.pending, (state) => {
				state.articlesList = []
				state.loading = true
			})
			.addCase(getArticleList.fulfilled, (state, action) => {
				state.loading = false
				state.articlesList = action.payload.data
				state.articleFilters = action.payload.filters
				state.articlePagination = action.payload.pagination
			})
			.addCase(getArticleList.rejected, (state) => {
				state.loading = false
			})

			// ^ Single article
			.addCase(getArticle.pending, (state) => {
				state.loading = true
			})
			.addCase(getArticle.fulfilled, (state, action) => {
				state.loading = false
				state.articleData = action.payload
			})
			.addCase(getArticle.rejected, (state) => {
				state.loading = false
			})

			// ^ Delete article
			.addCase(deleteArticle.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteArticle.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message, 5)
			})
			.addCase(deleteArticle.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload, 5)
			})

			// ^ Fetch categories list
			.addCase(getArticleCategories.pending, (state) => {
				state.articleCategoriesList = []
				state.loading = true
			})
			.addCase(getArticleCategories.fulfilled, (state, action) => {
				state.loading = false
				state.articleCategoriesList = action.payload
			})
			.addCase(getArticleCategories.rejected, (state) => {
				state.loading = false
			})

			// ^ Fetch sections list
			.addCase(getArticleSections.pending, (state) => {
				state.articleSectionsList = []
				state.loading = true
			})
			.addCase(getArticleSections.fulfilled, (state, action) => {
				state.loading = false
				state.articleSectionsList = action.payload
			})
			.addCase(getArticleSections.rejected, (state) => {
				state.loading = false
			})

			// ^ Add new category.
			.addCase(addCategory.pending, (state) => {
				state.loading = true
			})
			.addCase(addCategory.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message)
			})
			.addCase(addCategory.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload)
			})

			// ^ Delete category.
			.addCase(deleteCategory.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteCategory.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message)
			})
			.addCase(deleteCategory.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload)
			})

			// ^ Article gallery
			.addCase(getArticleGallery.pending, (state) => {
				state.articleGalleryLoaded = false
				state.loading = true
			})
			.addCase(getArticleGallery.fulfilled, (state, action) => {
				state.articleGalleryContent = action.payload.data
				state.articleGalleryOrders = []
				//
				state.articleGalleryLoaded = true
				state.loading = false
			})
			.addCase(getArticleGallery.rejected, (state) => {
				state.loading = false
			})

			// ^ Save article gallery
			.addCase(saveArticleGallery.pending, (state) => {
				state.loading = true
			})
			.addCase(saveArticleGallery.fulfilled, (state, action) => {
				state.articleGalleryOrders = []
				//
				state.loading = false
				message.success(action.payload.message, 5)
			})
			.addCase(saveArticleGallery.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload, 5)
			})

			// ^ Save side panel articles.
			.addCase(saveSidePanelArticles.pending, (state) => {
				state.loadingSidePanel = true
			})
			.addCase(saveSidePanelArticles.fulfilled, (state, action) => {
				state.loadingSidePanel = false
				message.success(action.payload.message, 5)
			})
			.addCase(saveSidePanelArticles.rejected, (state, action) => {
				state.loadingSidePanel = false
				message.error(action.payload, 5)
			})

			// ^ Get side panel articles.
			.addCase(getSidePanelArticles.pending, (state) => {
				state.loadingSidePanel = true
			})
			.addCase(getSidePanelArticles.fulfilled, (state, action) => {
				state.loadingSidePanel = false
				state.sidePanelArticles = action.payload
			})
			.addCase(getSidePanelArticles.rejected, (state) => {
				state.loadingSidePanel = false
				state.sidePanelArticles = []
			})
	},
})

export const { showLoading, disableLoading, updateArticleList } = articlesSlice.actions

export default articlesSlice.reducer
