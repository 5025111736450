import fetch from 'auth/FetchInterceptor'

const ArticlesService = {}

// & Articles.
ArticlesService.getArticles = function (params) {
	return fetch({
		url: '/articles',
		method: 'get',
		params,
	})
}

ArticlesService.saveArticleGallery = function (data) {
	return fetch({
		url: '/articles/order',
		method: 'post',
		data: data,
	})
}

ArticlesService.getArticle = function (articleId) {
	return fetch({
		url: `/articles/single/${articleId}`,
		method: 'get',
	})
}

ArticlesService.addOrUpdateArticle = function (data) {
	return fetch({
		url: '/articles',
		method: 'post',
		data: data,
	})
}

ArticlesService.deleteArticle = function (articleId) {
	return fetch({
		url: `/articles/${articleId}`,
		method: 'delete',
	})
}

// & Article categories.
ArticlesService.getArticleCategories = function () {
	return fetch({
		url: '/articles/categories',
		method: 'get',
	})
}

// & Article sections.
ArticlesService.getArticleSections = function () {
	return fetch({
		url: '/settings/sections',
		method: 'get',
	})
}

ArticlesService.addCategory = function (data) {
	return fetch({
		url: '/articles/categories',
		method: 'post',
		data: data,
	})
}

ArticlesService.deleteCategory = function (data) {
	return fetch({
		url: `/articles/categories/${data.id}`,
		method: 'delete',
	})
}

// & Article gallery.

ArticlesService.getArticleGallery = function () {
	return fetch({
		url: '/articles/gallery',
		method: 'get',
	})
}

// & Side panel.

ArticlesService.saveSidePanelArticles = function (data) {
	return fetch({
		url: '/articles/side-panel',
		method: 'post',
		data: data,
	})
}

ArticlesService.getSidePanelArticles = function () {
	return fetch({
		url: '/articles/side-panel',
		method: 'get',
	})
}

export default ArticlesService
