const envApiUrl = process.env.REACT_APP_API_URL

const dev = {
	API_ENDPOINT_URL: envApiUrl || '/api',
}

const prod = {
	API_ENDPOINT_URL: envApiUrl || '/api',
}

const test = {
	API_ENDPOINT_URL: envApiUrl || '/api',
}

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break
	}
}

export const env = getEnv()
