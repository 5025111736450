import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import MilestoneService from 'services/MilestoneService'

export const initialState = {
	loading: false,
	loadingUpdate: false,
	loadingDelete: false,
	loadingMilestoneSave: false,
	milestonesList: [],
}

// ^ Fetch milestones list.
export const getMilestones = createAsyncThunk(
	'milestones/get',
	async (data, { rejectWithValue }) => {
		try {
			const response = await MilestoneService.getMilestones()
			return response.result
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

// ^ Set milestones.
export const setMilestones = createAsyncThunk(
	'milestones/set',
	async (data, { rejectWithValue }) => {
		try {
			const response = await MilestoneService.setMilestonesOrder(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

// ^ Update connected articles.
export const updateConnectedArticles = createAsyncThunk(
	'milestones/articles',
	async (data, { rejectWithValue }) => {
		try {
			const response = await MilestoneService.updateConnectedArticles(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

// ^ Save custom milestone.
export const saveCustomMilestone = createAsyncThunk(
	'milestones/custom/save',
	async (data, { rejectWithValue }) => {
		try {
			const response = await MilestoneService.saveCustomMilestone(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

// ^ Switch milestone visibility.
export const switchVisibility = createAsyncThunk(
	'milestones/visibility',
	async (id, { rejectWithValue }) => {
		try {
			const response = await MilestoneService.switchVisibility({ id: id })
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

// ^ Delete custom milestone.
export const deleteCustomMilestone = createAsyncThunk(
	'milestones/delete',
	async (id, { rejectWithValue }) => {
		try {
			const response = await MilestoneService.deleteCustomMilestone({ id: id })
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const milestonesSlice = createSlice({
	name: 'milestones',
	initialState,
	reducers: {
		addNewCustomMilestone: (state, action) => {
			const customMilestones = state.milestonesList.find(
				(v) => v.id === action.payload
			).customMilestones

			customMilestones.unshift({
				id: parseInt('-' + (customMilestones.length + 1)),
				sectionId: action.payload,
				title: '',
				description: null,
				date: null,
				mediaId: null,
				media: null,
			})
		},
		deleteTempCustomMilestone: (state, action) => {
			const { milestoneId, customMilestoneId } = action.payload

			const customMilestones = state.milestonesList.find((v) => v.id === milestoneId)?.customMilestones
			const customMilestoneIndex = customMilestones.findIndex((v) => v.id === customMilestoneId)
			if (customMilestoneIndex !== -1) customMilestones.splice(customMilestoneIndex, 1)
		},
	},
	extraReducers: (builder) => {
		builder
			// ^ Get milestones.
			.addCase(getMilestones.pending, (state) => {
				state.loading = true
			})
			.addCase(getMilestones.fulfilled, (state, action) => {
				state.loading = false
				state.milestonesList = action.payload
			})
			.addCase(getMilestones.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload, 5)
			})

			// ^ Set milestones / change order.
			.addCase(setMilestones.pending, (state) => {
				state.loading = true
			})
			.addCase(setMilestones.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message, 5)
			})
			.addCase(setMilestones.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload, 5)
			})

			// ^ Update connected articles.
			.addCase(updateConnectedArticles.pending, (state) => {
				state.loadingUpdate = true
			})
			.addCase(updateConnectedArticles.fulfilled, (state, action) => {
				state.loadingUpdate = false
				message.success(action.payload.message, 5)
			})
			.addCase(updateConnectedArticles.rejected, (state, action) => {
				state.loadingUpdate = false
				message.error(action.payload, 5)
			})

			// ^ Save custom milestone.
			.addCase(saveCustomMilestone.pending, (state) => {
				state.loadingMilestoneSave = true
			})
			.addCase(saveCustomMilestone.fulfilled, (state, action) => {
				state.loadingMilestoneSave = false
				message.success(action.payload.message, 5)
			})
			.addCase(saveCustomMilestone.rejected, (state, action) => {
				state.loadingMilestoneSave = false
				message.error(action.payload, 5)
			})

			// ^ Switch milestone visibility.
			.addCase(switchVisibility.pending, (state) => {
				state.loadingUpdate = true
			})
			.addCase(switchVisibility.fulfilled, (state, action) => {
				state.loadingUpdate = false
				message.success(action.payload.message, 5)
			})
			.addCase(switchVisibility.rejected, (state, action) => {
				state.loadingUpdate = false
				message.error(action.payload, 5)
			})

			// ^ Delete custom milestone.
			.addCase(deleteCustomMilestone.pending, (state) => {
				state.loadingDelete = true
			})
			.addCase(deleteCustomMilestone.fulfilled, (state, action) => {
				state.loadingDelete = false
				message.success(action.payload.message, 5)
			})
			.addCase(deleteCustomMilestone.rejected, (state, action) => {
				state.loadingDelete = false
				message.error(action.payload, 5)
			})
	},
})

export const { addNewCustomMilestone, deleteTempCustomMilestone } = milestonesSlice.actions

export default milestonesSlice.reducer
