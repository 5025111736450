import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onBlankLayout } from 'store/slices/themeSlice'

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		const isBlank = blankLayout ? true : false
		dispatch(onBlankLayout(isBlank))
	}, [dispatch, blankLayout])

	return <Component {...props} />
}

export default AppRoute
