import fetch from 'auth/FetchInterceptor'

const MilestoneService = {}

MilestoneService.getMilestones = function (params) {
	return fetch({
		url: '/milestones',
		method: 'get',
		params,
	})
}

MilestoneService.setMilestonesOrder = function (data) {
	return fetch({
		url: '/milestones/order',
		method: 'post',
		data: data,
	})
}

MilestoneService.saveCustomMilestone = function (data) {
	return fetch({
		url: '/milestones',
		method: 'post',
		data: data,
	})
}

MilestoneService.updateConnectedArticles = function (data) {
	return fetch({
		url: '/milestones/articles',
		method: 'post',
		data: data,
	})
}

MilestoneService.switchVisibility = function (data) {
	return fetch({
		url: '/milestones/visibility',
		method: 'post',
		data: data,
	})
}

MilestoneService.deleteCustomMilestone = function (data) {
	return fetch({
		url: `/milestones/${data.id}`,
		method: 'delete',
	})
}

export default MilestoneService
