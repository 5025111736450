import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import MediaService from 'services/MediaService'

export const initialState = {
	loading: false,
	files: [],
	folders: [],
}

// & Upload files action.
export const uploadFiles = createAsyncThunk('media/upload', async (data, { rejectWithValue }) => {
	const { path, files } = data

	try {
		const response = await MediaService.upload({ path, files })
		return response.result
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// & Get folders and files.
export const getMedia = createAsyncThunk('media/list', async (params, { rejectWithValue }) => {
	try {
		const response = await MediaService.fetchFiles(params)
		return response.result
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// & Delete files.
export const deleteMedia = createAsyncThunk('media/delete', async (data, { rejectWithValue }) => {
	try {
		const response = await MediaService.deleteFiles({ objects: data })
		return response
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// & Create directory action.
export const createDirectory = createAsyncThunk(
	'media/createDir',
	async (dirName, { rejectWithValue }) => {
		try {
			const response = await MediaService.createDirectory({ name: dirName })
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const mediaSlice = createSlice({
	name: 'media',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// ^ Upload files.
			.addCase(uploadFiles.pending, (state) => {
				state.loading = true
			})
			.addCase(uploadFiles.fulfilled, (state, action) => {
				state.loading = false

				// & Error messages.
				for (const err of action.payload.errors) {
					message.error(err.filename + ' - ' + err.error, 5)
				}

				// & Success messages.
				for (const upload of action.payload.uploaded) {
					message.success(upload.filename + ' - successfully uploaded.', 5)
				}
			})
			.addCase(uploadFiles.rejected, (state, action) => {
				state.loading = false
			})

			// ^ Delete files or folders.
			.addCase(deleteMedia.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteMedia.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message, 5)
			})
			.addCase(deleteMedia.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload.error, 5)
			})

			// ^ Get media files.
			.addCase(getMedia.pending, (state) => {
				state.loading = true
			})
			.addCase(getMedia.fulfilled, (state, action) => {
				state.loading = false
				state.files = action.payload.files
				state.folders = action.payload.folders
			})
			.addCase(getMedia.rejected, (state) => {
				state.loading = false
			})

			// ^ Create new directory.
			.addCase(createDirectory.pending, (state) => {
				state.loading = true
			})
			.addCase(createDirectory.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message)
			})
			.addCase(createDirectory.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload)
			})
	},
})

export default mediaSlice.reducer
