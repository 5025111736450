import fetch from 'auth/FetchInterceptor'

const NewsletterService = {}

// & Newsletter subscribers.
NewsletterService.getSubscribers = function (params) {
	return fetch({
		url: '/newsletter/subscribers',
		method: 'get',
		params,
	})
}

NewsletterService.deleteSubscriber = function (articleId) {
	return fetch({
		url: `/newsletter/subscribers/${articleId}`,
		method: 'delete',
	})
}

NewsletterService.exportSubscribers = function (params) {
	return fetch({
		url: '/newsletter/export',
		method: 'get',
		params,
		responseType: 'blob',
	})
}

export default NewsletterService
