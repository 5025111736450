import { combineReducers } from 'redux'
import articles from './slices/articlesSlice'
import auth from './slices/authSlice'
import media from './slices/mediaSlice'
import newsletter from './slices/newsletterSlice'
import settings from './slices/settingsSlice'
import theme from './slices/themeSlice'
import milestones from './slices/milestonesSlice'

const rootReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		theme,
		auth,
		articles,
		newsletter,
		settings,
		media,
		milestones,
		...asyncReducers,
	})
	return combinedReducer(state, action)
}

export default rootReducer
