import fetch from 'auth/FetchInterceptor'

const SettingsService = {}

// * Homepage
SettingsService.getHomepageSettings = function () {
	return fetch({
		url: '/settings/homepage',
		method: 'get',
	})
}
SettingsService.setHomepageSettings = function (data) {
	return fetch({
		url: '/settings/homepage',
		method: 'post',
		data: data,
	})
}

// * General
SettingsService.getSettings = function (data) {
	return fetch({
		url: '/settings',
		method: 'get',
		params: data,
	})
}
SettingsService.setSettings = function (data) {
	return fetch({
		url: '/settings',
		method: 'post',
		data: data,
	})
}

export default SettingsService
