import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		data: data,
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data,
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post',
	})
}

AuthService.requestResetPassword = function (data) {
	return fetch({
		url: '/auth/password/reset',
		method: 'post',
		data: data,
	})
}

AuthService.setNewPassword = function (data) {
	return fetch({
		url: '/auth/password/new',
		method: 'post',
		data: data,
	})
}

AuthService.checkResetPasswordHash = function (data) {
	return fetch({
		url: '/auth/password/hash/validate',
		method: 'post',
		data: data,
	})
}

export default AuthService
