import { notification } from 'antd'
import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'constants/AuthConstant'
import { signOutSuccess } from 'store/slices/authSlice'
import store from '../store'

const unauthorizedCode = [400, 401]
let preventNotify = false

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(
	(config) => {
		const jwtToken = localStorage.getItem(AUTH_TOKEN) || null

		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
		}

		return config
	},
	(error) => {
		// Do something with request error here
		notification.error({
			message: 'Error',
		})
		Promise.reject(error)
	}
)

// API response interceptor
service.interceptors.response.use(
	(response) => {
		return response.data
	},
	(error) => {
		let notificationParam = {
			message: '',
		}

		// Remove token and redirect
		if (unauthorizedCode.includes(error.response.status)) {
			notificationParam.message = 'Authentication failed'
			notificationParam.description = error.response.data.message
			localStorage.removeItem(AUTH_TOKEN)

			store.dispatch(signOutSuccess())

			if (!preventNotify) {
				notification.error(notificationParam)
				preventNotify = true
			}

			setTimeout(() => {
				preventNotify = false
			}, 5000)
		}

		/* if ([403, 404, 500, 508].includes(error.response.status)) {
			notificationParam.message = error.response.data.message
		}

		notification.error(notificationParam) */

		return Promise.reject(error)
	}
)

export default service
