import fetch from 'auth/FetchInterceptor'

const MediaService = {}

MediaService.fetchFiles = function (params) {
	return fetch({
		url: '/media',
		method: 'get',
		params,
	})
}

MediaService.deleteFiles = function (params) {
	return fetch({
		url: `/media`,
		method: 'delete',
		data: params,
	})
}

MediaService.upload = function ({ path, files }) {
	let formData = new FormData()

	for (const file of files) {
		formData.append('files', file)
	}

	// Append path in form data.
	formData.append('path', path)

	return fetch({
		url: `/media/upload`,
		method: 'post',
		data: formData,
	})
}

MediaService.createDirectory = function (params) {
	return fetch({
		url: '/media/folder',
		method: 'post',
		data: params,
	})
}

export default MediaService
