import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import { AUTH_TOKEN, USER_DATA } from 'constants/AuthConstant'
import AuthService from 'services/AuthService'

export const initialState = {
	loading: false,
	loadingHash: false,
	message: '',
	showMessage: false,
	showNewPassword: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	userData: localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : null,
}

export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
	const { email, password } = data

	try {
		const response = await AuthService.login({ email, password })
		const { token, user } = response.result

		localStorage.setItem(AUTH_TOKEN, token)
		localStorage.setItem(USER_DATA, JSON.stringify(user))
		return { token, user }
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/register', async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.register({ email, password })
		const token = response.token
		localStorage.setItem(AUTH_TOKEN, token)
		return token
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout', async () => {
	localStorage.removeItem(AUTH_TOKEN)
	localStorage.removeItem(USER_DATA)
})

export const requestResetPassword = createAsyncThunk(
	'auth/reset-password/request',
	async (data, { rejectWithValue }) => {
		try {
			const response = await AuthService.requestResetPassword({ email: data })
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const checkResetPasswordHash = createAsyncThunk(
	'auth/reset-password/hash/validate',
	async (data, { rejectWithValue }) => {
		try {
			const response = await AuthService.checkResetPasswordHash(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message)
		}
	}
)

export const setNewPassword = createAsyncThunk(
	'auth/reset-password/new',
	async (data, { rejectWithValue }) => {
		try {
			const response = await AuthService.setNewPassword(data)
			return response
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
)

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload.token
			state.userData = action.payload.user
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.userData = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload.token
			state.userData = action.payload.user
		},
	},
	extraReducers: (builder) => {
		builder
			// ^ Sign In
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userData = action.payload.user
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})

			// ^ Sign Out
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
				state.userData = null
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})

			// ^ Sign Up
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})

			// ^ Request reset password.
			.addCase(requestResetPassword.pending, (state) => {
				state.loading = true
			})
			.addCase(requestResetPassword.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message, 5)
			})
			.addCase(requestResetPassword.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload, 5)
			})

			// ^ Check reset password hash.
			.addCase(checkResetPasswordHash.pending, (state) => {
				state.loadingHash = true
			})
			.addCase(checkResetPasswordHash.fulfilled, (state, action) => {
				state.loadingHash = false
				state.showNewPassword = action.payload.result
			})
			.addCase(checkResetPasswordHash.rejected, (state, action) => {
				state.loadingHash = false
				//
				state.showNewPassword = false
				state.showMessage = true
				state.message = action.payload
			})

			// ^ Set new password.
			.addCase(setNewPassword.pending, (state) => {
				state.loading = true
			})
			.addCase(setNewPassword.fulfilled, (state, action) => {
				state.loading = false
				message.success(action.payload.message, 5)
			})
			.addCase(setNewPassword.rejected, (state, action) => {
				state.loading = false
				message.error(action.payload, 5)
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
} = authSlice.actions

export default authSlice.reducer
